<template>
  <div
    :class="[
      'bottom-operation',
      {
        'bottom-operation__direction': closeLeft,
      },
    ]"
  >
    <div
      v-if="!closeLeft"
      class="bottom-operation__left"
      aria-hidden="true"
      :adaflag="`${sum}${language.SHEIN_KEY_PWA_15499}`"
      :aria-label="`${sum}${language.SHEIN_KEY_PWA_15499}`"
    >
      <div>{{ sum }}</div>
      {{ language.SHEIN_KEY_PWA_15499 }}
    </div>
    <div class="bottom-operation__right">
      <s-button
        v-if="isShowCancel"
        :type="['H56PX']"
        role="button"
        :adaflag="resetLang"
        :aria-label="resetLang"
        aria-hidden="true"
        block
        @click="reset"
      >
        {{ resetLang }}
      </s-button>
      <s-button
        :class="btnLoadingClass"
        :type="['primary', 'H56PX']"
        role="button"
        aria-hidden="true"
        :adaflag="`Close`"
        aria-label="Close"
        @click="done"
      >
        {{ language.SHEIN_KEY_PWA_15042 }}
        <s-loading 
          v-if="isShein" 
          :show="loading"
          size="small" 
          mode="light"
        />
      </s-button>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { SLoading } from '@shein-aidc/sui-loading/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
import { isShein } from '../../../../product_list_v2/js/utils'

export default defineComponent({
  name: 'BottomOperation',
  components: {
    SButton,
    SLoading,
  },
  emits: ['reset', 'done', 'componentReady'],
  props: {
    sum: {
      type: Number,
      default: 0,
    },
    language: {
      type: Object,
      default: () => ({}),
    },
    closeLeft: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false
    },
    isShowCancel: {
      type: Boolean,
      default: true
    }
  },
  data() {
    this.isShein = isShein()
    return {
      resetLang: gbCommonInfo?.language?.SHEIN_KEY_PWA_25346 || 'Clear',
    }
  },
  computed: {
    btnLoadingClass({ loading, isShein }) {
      return { 'with-loading': isShein && loading }
    }
  },
  mounted() {
    this.$emit('componentReady')
  },
  methods: {
    reset() {
      if (this.loading) return
      this.$emit('reset')
    },
    done() {
      if (this.isShein && this.loading) return
      this.$emit('done')
    },
  },
})
</script>

<style lang="less">
.bottom-operation {
  .flexbox();
  .space-between();
  .align-center();
  height: 1.17rem;
  margin-left: var(--negative-margin, -0.32rem);
  margin-right: var(--negative-margin, -0.32rem);
  padding: 0 var(--operation-padding, 0.32rem);
  border-top: 1px solid #e5e5e5;
  &__direction {
    flex-direction: row-reverse;
  }

  &__left {
    color: #222;
    > div {
      .font-dpr(28px);
      font-weight: bold;
    }
  }
  &__right {
    .flexbox();
    .align-center();
    &_reset {
      color: #666;
      .font-dpr(32px);
    }
  }
}
.bottom-operation__right {
  .sui-button-common {
    .font-dpr(28px);
    margin-left: 0.32rem;
    text-transform: capitalize;
    min-width: 0;
    padding: 0 0.32rem;
    font-weight: normal;
  }

  .with-loading {
    position: relative;
    color: transparent;
    .sui-loading {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      align-items: center;
    }
    .sui-loading__common-inner {
      margin: 0 auto;
    }
    .sui-loading__circular-path-under {
      stroke: rgba(255, 255, 255, .3);
    }
    .sui-loading__circular-path {
      stroke: rgba(255, 255, 255, 1);
    }
  }

}
</style>
